%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

* {
  box-sizing: border-box;
}

html, body {
  overflow: hidden;
  position: relative;
  color: $text-color;
  font-family: 'Roboto', sans-serif;
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.7rem;
  height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

.main {
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4 {
  color: $anne-red;
  font-weight: 400;
}

h1 {
  margin: 0;
}

h2, h3, h4 {
  margin: $base-padding*2 0 $base-padding*2;
  font-size: 1.5em;
  line-height: 1.2em;

  + p {
    margin-top: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;

  &:focus, &:hover {
    text-decoration: underline;
    color: $link-color-hover;
  }
}

.hide {
  display: none;
}

.error,
.success {
  border-radius: $base-border-radius;
  padding: $base-padding*2;
  color: white;
  font-weight: bold;
  margin: $base-padding*2 0;
}

.success {
  background-color: rgba($success, .5);
}

.error {
  background-color: rgba($error, .5);
}