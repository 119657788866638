/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto-v30-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}