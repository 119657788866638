/**
 *  Anne Mart.in placeholder site
 *
 *  06.09.2015
 *  V2 12.08.2022
 */

$text-color             :#252528;
$text-light             :#f7f7f7;
$anne-red               :#d1443d;
$background-color       :#fff;
$background-color-dark  :#333;
$link-color             :$anne-red;
$link-color-hover       :$text-color;
$success                :green;
$error                  :red;

$required-color         :#29291a;
$input-color            :#e7e7e7;
$input-color-focus      :#d2d295;

$max-content-width      :70rem;
$base-border-radius     :3px;
$base-perspective       :300px;
$base-padding           :.3rem;

$bp-tablet              :900px;
$bp-small-tablet        :768px;
$bp-phone               :620px;
$bp-small-phone         :360px;

$image-path             :'../img';
$image-background-path  :'#{$image-path}/backgrounds';
$image-signature        :url('#{$image-path}/signature.png');
$image-gradient         :url('#{$image-path}/gradient.jpg');

@import "include/normalize";

@import "include/fonts";
@import "include/common";
@import "include/layout";
@import "include/form";
@import "include/sections";
@import "include/breakpoints/desktop";
@import "include/breakpoints/tablet";
@import "include/breakpoints/phone";