@media(max-width: $bp-small-tablet) {
  .wrapper {
    section#home ~ section {
      position: relative;
      min-height: 100%;
      height: auto;

      > .scroll-layer--front {
        position: relative;
      }
    }
  }
}

// images
@media(max-width: $bp-tablet) {
  #work-2 .scroll-layer--back {
    //min-height: 150rem;
    top: 80vh;
  }

  @include homeBackgroundImage($bp-tablet);
  @include work1BackgroundImage($bp-tablet);
  @include work2BackgroundImage($bp-tablet);
}

@media(max-width: $bp-small-tablet) {
  @include homeBackgroundImage($bp-small-tablet);
  @include work1BackgroundImage($bp-small-tablet);
  @include work2BackgroundImage($bp-small-tablet);

  footer {
    .content {
      display: block;

      > div {
        width: 100%;
      }

      .contact,
      .photography {
        display: table;
        text-align: center;
        padding-left: $base-padding*2;
        margin-bottom: $base-padding*3;

        h4 {
          display: table-cell;
          width: 40%;
          text-align: left;
        }

        a, span {
          display: table-cell;
          width: 60%;
          text-align: left;
        }
      }

      .terms {
        display: block;
        padding-left: $base-padding*2;
        margin-top: $base-padding*6;
        text-align: center;
      }
    }
  }
}