#home {
  z-index: 15;
  user-select: none;

  .scroll-layer--back {
    transform: translateZ(0);
    background: transparent $image-gradient repeat-x;
    background-size: contain;
  }

  .image {
    position: relative;
    height: 100%;
    width: 100%;
    background: transparent none no-repeat center;
    background-size: cover;
  }

  .scroll-layer--front {
    //transform: translateZ($base-perspective) scale(0.5);

    h1 {
      position: absolute;
      bottom: 45%;
      left: 50%;
      transform: translate(-50%,50%);
      color: transparent;
      background: transparent $image-signature no-repeat center;
      background-size: contain;
      font-family: cursive;
      font-size: 5em;
      font-weight: 100;
      height: 300px;
      width: 400px;
      max-width: 90%;
    }
  }
}