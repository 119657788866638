@use "sass:math";

label {
  cursor: pointer;
}

form {
  .input-box {
    clear: both;
    padding: 1em 0;
  }

  label {
    float: right;
    width: math.percentage(math.div(1, 3));
    padding: $base-padding;
    text-align: right;
    cursor: default;

    em {
      color: $required-color;
    }
  }

  input, textarea, select, button {
    border-radius: $base-border-radius;
  }

  input, textarea, select {
    width: math.percentage(math.div(2, 3));

    border: 1px solid $input-color;
    background-color: transparent;
    padding: $base-padding;

    &:focus {
      border-color: $input-color-focus;
      outline: 0;
    }
  }

  textarea {
    height: 7em;
    resize: none;
  }

  button {
    background: linear-gradient(white, $background-color);
    border: 1px solid $input-color;
    padding: $base-padding*1.5 $base-padding*3;
    margin: 0 auto;
    display: block;
    min-width: 9em;

    &:hover {
      border-color: $input-color-focus;
    }
  }
}

@media(max-width: #{$bp-small-phone}) {
  form {
    .input-box {
      label {
        float: none;
        display: block;
        width: 100%;
        text-align: left;
        padding-left: $base-padding*2;

        em {
          position: absolute;
          margin-left: $base-padding*-2;
        }
      }
    }

    input, textarea, select, button {
      width: 100%;
      display: block;
    }
  }
}