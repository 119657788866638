@use "sass:math";

html, body {
  width: 100%;
  height: 100%;
  position: fixed;
}

body {
  perspective: $base-perspective;
}

.wrapper {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: $base-perspective;
  //perspective-origin-x: 100%;
  scroll-behavior: smooth;

  // chrome address-bar fix
  min-height: calc(var(--vh, 1vh) * 100);

  section {
    position: relative;
    height: 100vh;
    width: 100%;
    transform-style: preserve-3d;
  }

  .scroll-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //perspective-origin-x: 100%;

    &.scroll-layer--front {
      transform: translateZ(0);
    }

    &.scroll-layer--back {
      transform: translateZ($base-perspective * -1) scale(2);
    }

    &.scroll-layer--deep {
      transform: translateZ($base-perspective * -2) scale(3);
    }
  }
}

.content-wrapper {
  padding: $base-padding*4 $base-padding*5;
  background-color: $background-color;
  color: $text-color;

  &.gap {
    margin-top: 30vh;
  }

  &.fixed {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &.transparent {
    background-color: rgba($background-color, .9);
  }

  &.dark {
    background-color: $background-color-dark;
    color: $text-light;
    text-shadow: 0 0 0; // font fix for dark background

    &.transparent {
      background-color: rgba($background-color-dark, .9);
    }

    a {
      color: lighten($anne-red, 10%);
    }
  }

  .content {
    max-width: $max-content-width;
    margin: 0 auto;
  }
}

header {
  user-select: none;
  position: fixed;
  top: 0;
  right: 0;
  padding-right: $base-padding*2;
  width: 100%;
  z-index: 100;
  text-align: right;
  background: rgba(white, 0.95);
  max-height: 0;
  overflow: hidden;

  .head-wrapper {
    position: relative;
    height: 100%;
    max-width: $max-content-width;
    margin: 0 auto;

    > a {
      position: absolute;
      top: 50%;
      left: $base-padding*2;
      transform: translate(0, -50%);
      display: block;
      background: transparent $image-signature no-repeat center;
      background-size: contain;
      width: 15rem;
      height: 80%;
      color: transparent;
    }
  }

  nav#navigation {
    ul {
      display: inline-block;
      list-style: none;
    }

    li {
      display: inline-block;
      padding-left: $base-padding*3;
      font-size: 110%;
    }
  }
}

html.no-js {
  header {
    border-bottom: 1px solid rgba($anne-red, 0.3);
  }
}

html.js {
  header {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
  }

  body.fixed-header header {
    max-height: 100px;
    border-bottom: 1px solid rgba($anne-red, 0.3);
  }
}

footer {
  @extend %clearfix;
  position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 100;
  text-shadow: 0 0 0;
  font-size: 1.1rem;

  a {
    color: $text-light !important;

    &:hover {
      color: $anne-red !important;
      text-decoration: underline;
    }
  }

  .content {
    display: table;
    width: 100%;
    table-layout: fixed;

    > div {
      display: table-cell;
      width: math.percentage(math.div(1, 3));
      vertical-align: bottom;
    }

    .photography {
      padding-left: 10%;
    }

    .terms {
      text-align: right;
      padding-right: $base-padding*2;
    }
  }
}


/** debug ***/
body:target {
  .scroll-layer {
    box-shadow: 0 0 0 2px $anne-red;
    opacity: 0.8;
    background-color: rgba(black, .2);
  }

  section {
    transform: translate3d(700px, 0, -800px) rotateY(30deg);
  }
}
