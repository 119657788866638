@media(max-width: $bp-phone) {
  nav#navigation {
    li ~ li {
      display: none;
    }
  }

  header .head-wrapper>a {
    left: -2rem;
  }

  #work-1 {
    .scroll-layer--back {
      background-size: auto 100%;
    }
  }

  .content-wrapper {
    &.gap {
      margin-top: 15vh;
    }
  }

  #work-2 .scroll-layer--back {
    min-height: 60rem;
    //bottom: -50vh;
    top: 120vh;
  }

  body.impressum .impressum {
    dt,dd {
      display: block;
      width: 100%;
    }
  }


  footer {
    bottom: calc(var(--vh, 1vh) * 5);
  }

  footer .content {
    .terms,
    .contact,
    .photography {
      display: block;

      h4,a, span {
        display: block;
        width: 100%;
        text-align: left;
      }
    }
  }

  // images
  #home .scroll-layer--front h1 {
    background-image: url('#{$image-path}/signature-phone.png');
  }

  @include homeBackgroundImage($bp-phone);
  @include work1BackgroundImage($bp-phone);
  @include work2BackgroundImage($bp-phone);
}

@media(max-width: $bp-small-phone) {
  #home .scroll-layer--front h1 {
    background-image: url('#{$image-path}/signature-small-phone.png');
  }

  @include homeBackgroundImage($bp-small-phone);
  @include work1BackgroundImage($bp-small-phone);
  @include work2BackgroundImage($bp-small-phone);


  #work-2 .scroll-layer--back {
    min-height: 90rem;
    bottom: -60vh;
  }
}