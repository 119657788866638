@import "sections/home";
@import "sections/impressum";
@import "sections/work";
@import "sections/welcome";

/** background images **/
@mixin homeBackgroundImage($background-image-width) {
  #home .scroll-layer--back .image {
    background-image: url('#{$image-background-path}/anne.jpg?width=#{$background-image-width}');
    background-image: url('#{$image-background-path}/anne.jpg?as=webp&width=#{$background-image-width}');
  }
}

@mixin work1BackgroundImage($background-image-width) {
  #work-1 .scroll-layer--back {
    background-image: url('#{$image-background-path}/shoes.jpg?width=#{$background-image-width}');
    background-image: url('#{$image-background-path}/shoes.jpg?as=webp&width=#{$background-image-width}');
  }
}

@mixin work2BackgroundImage($background-image-width) {
  #work-2 .scroll-layer--back {
    background-image: url('#{$image-background-path}/anne2.jpg?width=#{$background-image-width}');
    background-image: url('#{$image-background-path}/anne2.jpg?as=webp&width=#{$background-image-width}');
  }
}