#work-1 {
  z-index: 3;

  .scroll-layer--back {
    transform: translateZ(($base-perspective * -1) -  50) scale(2.9);
    background: transparent none no-repeat center;
    background-size: cover;
  }

  .scroll-layer--front {
    margin-top: 40vh;
    //height: 60vh;
  }
}

#work-2 {
  z-index: 4;

  .scroll-layer--back {
    transform: translateZ(0) scale(1);
    background: transparent none no-repeat center;
    background-size: cover;
    top: 45vh;
    bottom: -30vh;
    min-height: 60rem;
  }

  .scroll-layer--front {
    margin-top: 30vh;
    height: 60vh;
  }
}