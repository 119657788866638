body.impressum {
  $headerSize: 25vh;

  header {
    max-height: $headerSize;
  }

  > .wrapper {
    position: absolute;
    margin-top: $headerSize;
    overflow: visible;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: $headerSize*-1;
      width: 100%;
      height: $headerSize;
      left: 0;
      background: transparent url('#{$image-background-path}/anne.jpg?width=1500&height=600') no-repeat center top;
      background-size: cover;
    }

    &:after {
      content: '';
      position: absolute;
      top: -2rem;
      height: 2rem;
      width: 100%;
      background: transparent;
      background-image: linear-gradient(top,rgba($background-color, 0), rgba($background-color, 100) 50%);
    }
  }

  .impressum {
    padding: $base-padding*2 $base-padding*3 8vh;
    max-width: $max-content-width;
    margin: 0 auto $base-padding*2;
    height: calc(100% - #{$headerSize});
    overflow-y: auto;

    h2, h3 {
      font-size: 1.3rem;
    }

    h3 {
      font-size: 1.3rem;
      margin-top: 3rem;
    }

    h4 {
      font-size: 1.05rem;
    }

    dl {
      margin-bottom: 2rem;

      dt,dd {
        display: inline-block;
      }

      dt {
        width: 10rem;
        font-weight: bold;
      }

      dd {
        width: calc(100% - 11rem);
        margin-left: 0
      }
    }
  }
}