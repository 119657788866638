html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url("roboto-v30-latin-100.f0eaf900.eot");
  src: local(""), url("roboto-v30-latin-100.f0eaf900.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-100.b393c3f0.woff2") format("woff2"), url("roboto-v30-latin-100.da82bbb9.woff") format("woff"), url("roboto-v30-latin-100.c3bd43cf.ttf") format("truetype"), url("roboto-v30-latin-100.37f04a55.svg#Roboto") format("svg");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("roboto-v30-latin-300.f063faeb.eot");
  src: local(""), url("roboto-v30-latin-300.f063faeb.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-300.d07a8413.woff2") format("woff2"), url("roboto-v30-latin-300.e1075c83.woff") format("woff"), url("roboto-v30-latin-300.fbcb71ed.ttf") format("truetype"), url("roboto-v30-latin-300.fbcf58aa.svg#Roboto") format("svg");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("roboto-v30-latin-regular.5825acfa.eot");
  src: local(""), url("roboto-v30-latin-regular.5825acfa.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-regular.57844c26.woff2") format("woff2"), url("roboto-v30-latin-regular.32450484.woff") format("woff"), url("roboto-v30-latin-regular.8bedb902.ttf") format("truetype"), url("roboto-v30-latin-regular.dca3b2be.svg#Roboto") format("svg");
}

footer:before, footer:after {
  content: "";
  display: table;
}

footer:after {
  clear: both;
}

* {
  box-sizing: border-box;
}

html, body {
  color: #252528;
  height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  font-family: Roboto, sans-serif;
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.7rem;
  position: relative;
  overflow: hidden;
}

.main {
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4 {
  color: #d1443d;
  font-weight: 400;
}

h1 {
  margin: 0;
}

h2, h3, h4 {
  margin: .6rem 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

h2 + p, h3 + p, h4 + p {
  margin-top: 0;
}

a {
  color: #d1443d;
  text-decoration: none;
}

a:focus, a:hover {
  color: #252528;
  text-decoration: underline;
}

.hide {
  display: none;
}

.error, .success {
  color: #fff;
  border-radius: 3px;
  margin: .6rem 0;
  padding: .6rem;
  font-weight: bold;
}

.success {
  background-color: #00800080;
}

.error {
  background-color: #ff000080;
}

html, body {
  width: 100%;
  height: 100%;
  position: fixed;
}

body {
  perspective: 300px;
}

.wrapper {
  width: 100%;
  height: 100vh;
  perspective: 300px;
  scroll-behavior: smooth;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper section {
  height: 100vh;
  width: 100%;
  transform-style: preserve-3d;
  position: relative;
}

.wrapper .scroll-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.wrapper .scroll-layer.scroll-layer--front {
  transform: translateZ(0);
}

.wrapper .scroll-layer.scroll-layer--back {
  transform: translateZ(-300px)scale(2);
}

.wrapper .scroll-layer.scroll-layer--deep {
  transform: translateZ(-600px)scale(3);
}

.content-wrapper {
  color: #252528;
  background-color: #fff;
  padding: 1.2rem 1.5rem;
}

.content-wrapper.gap {
  margin-top: 30vh;
}

.content-wrapper.fixed {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.content-wrapper.transparent {
  background-color: #ffffffe6;
}

.content-wrapper.dark {
  color: #f7f7f7;
  text-shadow: 0 0;
  background-color: #333;
}

.content-wrapper.dark.transparent {
  background-color: #333333e6;
}

.content-wrapper.dark a {
  color: #db6c66;
}

.content-wrapper .content {
  max-width: 70rem;
  margin: 0 auto;
}

header {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 100;
  text-align: right;
  max-height: 0;
  background: #fffffff2;
  padding-right: .6rem;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
}

header .head-wrapper {
  height: 100%;
  max-width: 70rem;
  margin: 0 auto;
  position: relative;
}

header .head-wrapper > a {
  width: 15rem;
  height: 80%;
  color: #0000;
  background: url("signature.3ad101d1.png") center / contain no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  left: .6rem;
  transform: translate(0, -50%);
}

header nav#navigation ul {
  list-style: none;
  display: inline-block;
}

header nav#navigation li {
  padding-left: .9rem;
  font-size: 110%;
  display: inline-block;
}

html.no-js header {
  border-bottom: 1px solid #d1443d4d;
}

html.js header {
  max-height: 0;
  transition: max-height .25s ease-in-out;
  overflow: hidden;
}

html.js body.fixed-header header {
  max-height: 100px;
  border-bottom: 1px solid #d1443d4d;
}

footer {
  width: 100vw;
  z-index: 100;
  text-shadow: 0 0;
  font-size: 1.1rem;
  position: absolute;
  bottom: 0;
}

footer a {
  color: #f7f7f7 !important;
}

footer a:hover {
  text-decoration: underline;
  color: #d1443d !important;
}

footer .content {
  width: 100%;
  table-layout: fixed;
  display: table;
}

footer .content > div {
  width: 33.3333%;
  vertical-align: bottom;
  display: table-cell;
}

footer .content .photography {
  padding-left: 10%;
}

footer .content .terms {
  text-align: right;
  padding-right: .6rem;
}

body:target .scroll-layer {
  opacity: .8;
  background-color: #0003;
  box-shadow: 0 0 0 2px #d1443d;
}

body:target section {
  transform: translate3d(700px, 0, -800px)rotateY(30deg);
}

label {
  cursor: pointer;
}

form .input-box {
  clear: both;
  padding: 1em 0;
}

form label {
  float: right;
  width: 33.3333%;
  text-align: right;
  cursor: default;
  padding: .3rem;
}

form label em {
  color: #29291a;
}

form input, form textarea, form select, form button {
  border-radius: 3px;
}

form input, form textarea, form select {
  width: 66.6667%;
  background-color: #0000;
  border: 1px solid #e7e7e7;
  padding: .3rem;
}

form input:focus, form textarea:focus, form select:focus {
  border-color: #d2d295;
  outline: 0;
}

form textarea {
  height: 7em;
  resize: none;
}

form button {
  min-width: 9em;
  background: linear-gradient(#fff, #fff);
  border: 1px solid #e7e7e7;
  margin: 0 auto;
  padding: .45rem .9rem;
  display: block;
}

form button:hover {
  border-color: #d2d295;
}

@media (max-width: 360px) {
  form .input-box label {
    float: none;
    width: 100%;
    text-align: left;
    padding-left: .6rem;
    display: block;
  }

  form .input-box label em {
    margin-left: -.6rem;
    position: absolute;
  }

  form input, form textarea, form select, form button {
    width: 100%;
    display: block;
  }
}

#home {
  z-index: 15;
  -webkit-user-select: none;
  user-select: none;
}

#home .scroll-layer--back {
  background: url("gradient.8b7c73ce.jpg") 0 0 / contain repeat-x;
  transform: translateZ(0);
}

#home .image {
  height: 100%;
  width: 100%;
  background: center / cover no-repeat;
  position: relative;
}

#home .scroll-layer--front h1 {
  color: #0000;
  height: 300px;
  width: 400px;
  max-width: 90%;
  background: url("signature.3ad101d1.png") center / contain no-repeat;
  font-family: cursive;
  font-size: 5em;
  font-weight: 100;
  position: absolute;
  bottom: 45%;
  left: 50%;
  transform: translate(-50%, 50%);
}

body.impressum header {
  max-height: 25vh;
}

body.impressum > .wrapper {
  margin-top: 25vh;
  position: absolute;
  overflow: visible;
}

body.impressum > .wrapper:before {
  content: "";
  width: 100%;
  height: 25vh;
  background: url("anne.453f716b.jpeg") top / cover no-repeat;
  display: block;
  position: absolute;
  top: -25vh;
  left: 0;
}

body.impressum > .wrapper:after {
  content: "";
  height: 2rem;
  width: 100%;
  background: none;
  background-image: linear-gradient(top, #fff0, white 50%);
  position: absolute;
  top: -2rem;
}

body.impressum .impressum {
  max-width: 70rem;
  height: calc(100% - 25vh);
  margin: 0 auto .6rem;
  padding: .6rem .9rem 8vh;
  overflow-y: auto;
}

body.impressum .impressum h2, body.impressum .impressum h3 {
  font-size: 1.3rem;
}

body.impressum .impressum h3 {
  margin-top: 3rem;
  font-size: 1.3rem;
}

body.impressum .impressum h4 {
  font-size: 1.05rem;
}

body.impressum .impressum dl {
  margin-bottom: 2rem;
}

body.impressum .impressum dl dt, body.impressum .impressum dl dd {
  display: inline-block;
}

body.impressum .impressum dl dt {
  width: 10rem;
  font-weight: bold;
}

body.impressum .impressum dl dd {
  width: calc(100% - 11rem);
  margin-left: 0;
}

#work-1 {
  z-index: 3;
}

#work-1 .scroll-layer--back {
  background: center / cover no-repeat;
  transform: translateZ(-350px)scale(2.9);
}

#work-1 .scroll-layer--front {
  margin-top: 40vh;
}

#work-2 {
  z-index: 4;
}

#work-2 .scroll-layer--back {
  min-height: 60rem;
  background: center / cover no-repeat;
  top: 45vh;
  bottom: -30vh;
  transform: translateZ(0)scale(1);
}

#work-2 .scroll-layer--front {
  height: 60vh;
  margin-top: 30vh;
}

#welcome {
  z-index: 5;
}

@media (min-width: 901px) {
  #home .scroll-layer--back .image {
    background-image: url("anne.de7c35c7.webp");
  }

  #work-1 .scroll-layer--back {
    background-image: url("shoes.d99a4035.webp");
  }

  #work-2 .scroll-layer--back {
    background-image: url("anne2.f6433f96.webp");
  }
}

@media (max-width: 768px) {
  .wrapper section#home ~ section {
    min-height: 100%;
    height: auto;
    position: relative;
  }

  .wrapper section#home ~ section > .scroll-layer--front {
    position: relative;
  }
}

@media (max-width: 900px) {
  #work-2 .scroll-layer--back {
    top: 80vh;
  }

  #home .scroll-layer--back .image {
    background-image: url("anne.56957bbf.webp");
  }

  #work-1 .scroll-layer--back {
    background-image: url("shoes.bd4bd0b6.webp");
  }

  #work-2 .scroll-layer--back {
    background-image: url("anne2.47d0db58.webp");
  }
}

@media (max-width: 768px) {
  #home .scroll-layer--back .image {
    background-image: url("anne.050b06f5.webp");
  }

  #work-1 .scroll-layer--back {
    background-image: url("shoes.a1f08d85.webp");
  }

  #work-2 .scroll-layer--back {
    background-image: url("anne2.271155e1.webp");
  }

  footer .content {
    display: block;
  }

  footer .content > div {
    width: 100%;
  }

  footer .content .contact, footer .content .photography {
    text-align: center;
    margin-bottom: .9rem;
    padding-left: .6rem;
    display: table;
  }

  footer .content .contact h4, footer .content .photography h4 {
    width: 40%;
    text-align: left;
    display: table-cell;
  }

  footer .content .contact a, footer .content .contact span, footer .content .photography a, footer .content .photography span {
    width: 60%;
    text-align: left;
    display: table-cell;
  }

  footer .content .terms {
    text-align: center;
    margin-top: 1.8rem;
    padding-left: .6rem;
    display: block;
  }
}

@media (max-width: 620px) {
  nav#navigation li ~ li {
    display: none;
  }

  header .head-wrapper > a {
    left: -2rem;
  }

  #work-1 .scroll-layer--back {
    background-size: auto 100%;
  }

  .content-wrapper.gap {
    margin-top: 15vh;
  }

  #work-2 .scroll-layer--back {
    min-height: 60rem;
    top: 120vh;
  }

  body.impressum .impressum dt, body.impressum .impressum dd {
    width: 100%;
    display: block;
  }

  footer {
    bottom: calc(var(--vh, 1vh) * 5);
  }

  footer .content .terms, footer .content .contact, footer .content .photography {
    display: block;
  }

  footer .content .terms h4, footer .content .terms a, footer .content .terms span, footer .content .contact h4, footer .content .contact a, footer .content .contact span, footer .content .photography h4, footer .content .photography a, footer .content .photography span {
    width: 100%;
    text-align: left;
    display: block;
  }

  #home .scroll-layer--front h1 {
    background-image: url("signature-phone.2d54a639.png");
  }

  #home .scroll-layer--back .image {
    background-image: url("anne.379bffc8.webp");
  }

  #work-1 .scroll-layer--back {
    background-image: url("shoes.037cb82c.webp");
  }

  #work-2 .scroll-layer--back {
    background-image: url("anne2.12547015.webp");
  }
}

@media (max-width: 360px) {
  #home .scroll-layer--front h1 {
    background-image: url("signature-small-phone.7665a6c0.png");
  }

  #home .scroll-layer--back .image {
    background-image: url("anne.b0d9e5c0.webp");
  }

  #work-1 .scroll-layer--back {
    background-image: url("shoes.d81d1cfa.webp");
  }

  #work-2 .scroll-layer--back {
    min-height: 90rem;
    background-image: url("anne2.9dcf942e.webp");
    bottom: -60vh;
  }
}

/*# sourceMappingURL=impressum.0b9b742e.css.map */
